<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <Menu/>
              </v-col>

              <v-col cols="12" lg="9">

                <v-card flat>

                  <v-card-text v-if="isLoading">
                    <v-row>
                      <v-col v-for="item in 4" :key="item" cols="12" lg="6">
                        <v-skeleton-loader type="article"/>
                      </v-col>
                    </v-row>
                  </v-card-text>


                  <div v-else>

                    <v-card-text>

                      <v-row>

                        <v-col cols="12" lg="6">
                          <v-card color="gifty" dark flat>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <h3 class="mb-2 white--text">DZD</h3>
                                  <div class="white--text font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_day) }}
                                  </div>
                                </v-col>
                                <v-col class="text-right">
                                  <v-avatar color="white">
                                    <v-icon color="gifty">mdi-currency-usd</v-icon>
                                  </v-avatar>
                                </v-col>
                              </v-row>

                              <div class="d-flex justify-space-between align-center mt-3">
                                <div class="white--text text-no-wrap">Total aujourd'hui</div>
                              </div>

                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12" lg="6">
                          <v-card outlined>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <h3 class="mb-2">DZD</h3>
                                  <div class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_week) }}
                                  </div>
                                </v-col>
                                <v-col class="text-right">
                                  <v-avatar color="primary">
                                    <v-icon dark>mdi-currency-usd</v-icon>
                                  </v-avatar>
                                </v-col>
                              </v-row>

                              <div class="d-flex justify-space-between align-center mt-3">
                                <div class="text-no-wrap">Total cette semaine</div>
                              </div>

                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12" lg="6">
                          <v-card :loading="monthLoading" outlined>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <h3 class="mb-2">DZD</h3>
                                  <v-skeleton-loader v-if="monthLoading"
                                                     type="text"/>
                                  <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_month) }}
                                  </div>
                                </v-col>
                                <v-col class="text-right">
                                  <v-avatar color="gifty">
                                    <v-icon dark>mdi-currency-usd</v-icon>
                                  </v-avatar>
                                </v-col>
                              </v-row>

                              <div class="d-flex justify-space-between align-center mt-3">
                                <div class="text-no-wrap">Total mois de {{ setMonth(month) }}</div>
                                <v-spacer/>
                                <div>
                                  <v-skeleton-loader v-if="monthLoading" type="text"/>
                                  <v-menu v-else
                                          bottom
                                          right transition="slide-x-transition"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="d-flex font-weight-medium gifty--text" v-bind="attrs"
                                           v-on="on">
                                        {{ setMonth(month) }}
                                        <v-icon color="gifty">mdi-chevron-down</v-icon>
                                      </div>
                                    </template>

                                    <v-list dense nav>
                                      <v-list-item v-for="(m, i) in months"
                                                   :key="i"
                                                   :class="m.value === month ? 'gifty white--text' : ''"
                                                   class="translateY"
                                                   link
                                                   @click="[month=m.value,getVouchersAmountByMonth()]"
                                      >
                                        <v-list-item-title>{{ m.name }}</v-list-item-title>
                                      </v-list-item>
                                    </v-list>

                                  </v-menu>
                                </div>
                              </div>

                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12" lg="6">
                          <v-card :loading="yearLoading" outlined>
                            <v-card-text>
                              <v-row>
                                <v-col>
                                  <h3 class="mb-2">DZD</h3>
                                  <v-skeleton-loader v-if="yearLoading"
                                                     type="text"/>
                                  <div v-else class="font-weight-medium">
                                    {{ CurrencyFormatting(statistics.current_year) }}
                                  </div>
                                </v-col>
                                <v-col class="text-right">
                                  <v-avatar color="primary">
                                    <v-icon dark>mdi-currency-usd</v-icon>
                                  </v-avatar>
                                </v-col>
                              </v-row>

                              <div class="d-flex justify-space-between align-center mt-3">
                                <span class="text-no-wrap">
                                  Total {{ year }}
                                </span>
                                <v-spacer/>
                                <div>
                                  <v-skeleton-loader v-if="yearLoading" type="text"/>
                                  <v-menu v-else
                                          bottom
                                          right transition="slide-x-transition"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="d-flex primary--text font-weight-medium" v-bind="attrs"
                                           v-on="on">
                                        {{ year }}
                                        <v-icon color="primary">mdi-chevron-down</v-icon>
                                      </div>
                                    </template>


                                    <v-list dense nav>
                                      <v-list-item v-for="(y) in years"
                                                   :key="y"
                                                   :class="y === year ? 'primary white--text' : ''"
                                                   :value="y"
                                                   @click="[year=y,getVouchersAmountByYear(y)]">
                                        <v-list-item-title>{{ y }}</v-list-item-title>
                                      </v-list-item>
                                    </v-list>

                                  </v-menu>
                                </div>
                              </div>

                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col cols="12">

                          <div class="text-end mb-3">
                            <v-btn color="gifty" text @click="dialog = true">
                              <v-icon left>mdi-download</v-icon>
                              Exporter
                            </v-btn>
                          </div>

                          <v-simple-table class="table-border rounded">
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th>Boutique</th>
                                <th>Aujourd'hui</th>
                                <th>Mois de {{ setMonth(month) }}</th>
                                <th>{{ year }}</th>
                                <th>Total</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(store,i) in statistics.stores" :key="i">

                                <td class="font-weight-medium">
                                  <div class="my-4">
                                    {{ store.name }}
                                  </div>
                                </td>

                                <td>
                                  <span class="font-weight-medium d-block">
                                    {{ CurrencyFormatting(store.amount_current_day) }}
                                  </span>
                                  <small class="gifty--text">
                                    <v-icon small color="gifty">mdi-swap-horizontal</v-icon>
                                    {{ store.transactions_current_day }}
                                  </small>
                                </td>

                                <td>
                                  <span class="font-weight-medium d-block">
                                    {{ CurrencyFormatting(store.amount_month) }}
                                  </span>
                                  <small class="gifty--text">
                                    <v-icon small color="gifty">mdi-swap-horizontal</v-icon>
                                    {{ store.transactions_month }}
                                  </small>
                                </td>


                                <td>
                                  <span class="font-weight-medium d-block">
                                    {{ CurrencyFormatting(store.amount_year) }}
                                  </span>
                                  <small class="gifty--text">
                                    <v-icon small color="gifty">mdi-swap-horizontal</v-icon>
                                    {{ store.transactions_year }}
                                  </small>
                                </td>

                                <td>
                                  <span class="font-weight-medium d-block">
                                    {{ CurrencyFormatting(store.amount_total) }}
                                  </span>
                                  <small class="primary--text">
                                    <v-icon small color="primary">mdi-swap-horizontal</v-icon>
                                    {{ store.transactions_total }}
                                  </small>
                                </td>

                              </tr>

                              <tr v-if="!statistics.stores.length">
                                <td colspan="3" class="text-center">
                                  Aucun élément enregistré
                                </td>
                              </tr>

                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>

                      </v-row>

                    </v-card-text>

                  </div>

                </v-card>

              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </div>


      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>
            Filtrer par dates
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <v-date-picker color="gifty" full-width range v-model="dates"></v-date-picker>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gifty"
                   :disabled="dates.length !== 2"
                   text
                   @click="exportExcel">
              <v-icon left>mdi-download</v-icon>
              Exporter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import Menu from "@/views/partner_vouchers/components/Menu";
import moment from "moment";

export default {
  components: {Menu},
  data() {
    return {
      dialog: false,
      isLoading: false,
      statistics: {},
      dates: [],

      monthLoading: false,
      yearLoading: false,

      month: moment().format('MM'),
      year: moment().format('YYYY'),
      currentMonth: moment().format('MM'),
      currentYear: moment().format('YYYY')
    };
  },
  methods: {
    api() {
      HTTP.get('/v1/partner-vouchers/global-statistics', {
        params: {
          month: this.month,
          year: this.year
        }
      }).then(res => {
        this.isLoading = false
        this.monthLoading = false
        this.yearLoading = false
        this.statistics = res.data.data
      }).catch(err => {
        this.isLoading = false
        this.monthLoading = false
        this.yearLoading = false
        console.log(err)
      })
    },
    getStatistics() {
      this.isLoading = true
      this.api()
    },
    getVouchersAmountByMonth() {
      this.monthLoading = true
      this.api()
    },
    getVouchersAmountByYear() {
      this.yearLoading = true
      this.api()
    },
    setMonth(month) {
      return this.months.find(el => el.value === month).name
    },
    exportExcel() {
      let user = this.$store.getters.getUser;
      let token = user.access_token;
      const DownloadURL =
          process.env.VUE_APP_BASE_URL +
          "v1/partner-vouchers/global-statistics/export" +
          "?token=" +
          token +
          "&dates=" +
          this.dates +
          "&user_id=" +
          user.user.id;
      window.open(DownloadURL, "_blanc");
    },
  },
  created() {
    this.getStatistics()
  },
  computed: {
    months() {
      return [
        {name: 'Janvier', value: "01"},
        {name: 'Février', value: "02"},
        {name: 'Mars', value: "03"},
        {name: 'Avril', value: "04"},
        {name: 'Mai', value: "05"},
        {name: 'Juin', value: "06"},
        {name: 'Juillet', value: "07"},
        {name: 'Août', value: "08"},
        {name: 'Septembre', value: "09"},
        {name: 'Octobre', value: "10"},
        {name: 'Novombre', value: "11"},
        {name: 'Décembre', value: "12"},
      ]
    },
    years() {
      return ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
    },
  }
};
</script>

<style scoped></style>
